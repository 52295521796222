"use strict";

// Document is ready?
var ready = function (fn) {
    if (typeof fn !== 'function') return;
    if (document.readyState === 'complete') {
        return fn()
    }
    document.addEventListener('DOMContentLoaded', fn, false);
};

// Imports
import {initArrayFromPolyfill} from './components/helpers';
import initSliders from './components/sliders';
import {headerTriggers, homeMoreSection, tabTriggers, partnersTriggers, filtersTriggers} from './components/triggers';
import {topParallax, newsLetterAnimation, lazyLoading, smoothScrolling, lightBox} from './components/snippets';
// import initConsentManager from "./components/vbcn";
import init from './components/vbcn';
import {vbcnOptions} from "./components/vbcn-options";

// Document is ready!
// ready(function() {

// Helpers
initArrayFromPolyfill();

// Rest
initSliders();

// Triggers
headerTriggers();
homeMoreSection();
tabTriggers();
partnersTriggers();
filtersTriggers();

// Snippets
topParallax();
newsLetterAnimation();
lazyLoading();
smoothScrolling();
lightBox();

// cookie notice
window.myVBCN = init(vbcnOptions);

function goBack() {
    window.history.back();
}

document.documentElement.className = "js"

document.querySelectorAll('.js-go-back').forEach(function (el) {
    el.addEventListener('click', goBack);
});

// });
