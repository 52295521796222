"use strict";

export function metalang() {
    let metalang = document.querySelector('html[lang]') ? document.querySelector('html').getAttribute('lang').toLowerCase() : false;
    if (!metalang || metalang.trim() === "") {
        return 'de'
    }
    return metalang;
}

export var lang = metalang();


export var mobilesettings = {
    Utils : {
        PHONE_TAG : "mobile",
        PHABLET_TAG : "phablet",
        TABLET_TAG : "tablet",
        DESKTOP_TAG : "desktop",
        DESKTOP_LARGE_TAG : "wide",
        DESKTOP_ULTRAWIDE_TAG : "ultrawide",
    }
};

// Track breakpoints in JS
export function getResponsiveTag () {
    var tag = window.getComputedStyle(document.body,':after').getPropertyValue('content');
    tag = tag.replace( /"|'/g,'');   // Firefox bugfix
    return tag;
};

export function isMobile () {
    return getResponsiveTag() === mobilesettings.Utils.PHONE_TAG || getResponsiveTag() === mobilesettings.Utils.PHABLET_TAG;
};

export function isTablet () {
    return getResponsiveTag() === mobilesettings.Utils.TABLET_TAG;
};

export function nodeListToArray (nodelist) {
    return [].slice.call(nodelist);
}

export function tableFlip () {
    console.log('(╯°□°）╯︵ ┻━┻');
};

export function  randomIntFromInterval (min,max) {
    return Math.floor(Math.random()*(max-min+1)+min);
};

export function getLayout (layoutContainer) {
    var layout = document.querySelector(layoutContainer).length > 0 ?  document.querySelector(layoutContainer).data('layout') : false;
    return layout;
};

export function getScreenWidth () {
    return window.innerWidth;
};

export function getBaseUrl() {
    var baseurl;
    if (document.querySelector('base')) {
        baseurl = document.querySelector('base').prop('href');
    } else {
        if (window.location.protocol != "https:") {
            baseurl = 'http://' + window.location.hostname;
        } else {
            baseurl = 'https://' + window.location.hostname;
        }
    }
    return baseurl;
}

export function findAncestorByClass (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

export function addClass(el, cls) {
    if (el.classList.contains(cls)) {
        return
    }
    el.classList.add(cls);
}

export function removeClass(el, cls) {
    if (el.classList.contains(cls)) {
        el.classList.remove(cls);
    }
}

export function hasClass (el, cls) {
    return el.classList.contains(cls)
}

export function toggleClass(el, cls) {
    if (el.classList.contains(cls)) {
        removeClass(el, cls)
    } else {
        addClass(el, cls)
    }
}

export function addStyle(el, styleKey, styleValue) {
    let elStyles = el.style;

    elStyles[styleKey] = styleValue;
}

export function wrapElement(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

export function getWindowScrollPosition() {
    return {
        x: window.pageXOffset || document.documentElement.scrollLeft,
        y: window.pageYOffset || document.documentElement.scrollTop
    };
}

export function scrollTo(element, to, duration) {
    if (duration <= 0) return;
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

export function encode_utf8(s) {
    return unescape(encodeURIComponent(s));
}

export function decode_utf8(s) {
    return decodeURIComponent(escape(s));
}
/**
 * Fixes SVGs attributes to include the window.location in case a base tag is present
 */
export function fixSVG () {
    /**
     * Current URL, without the hash
     */
    var baseUrl = window.location.href.replace(window.location.hash, "");

    /**
     *  Find all `use` elements with a namespaced `href` attribute, e.g.
     *  <use xlink:href="#some-id"></use>
     *
     *  See: http://stackoverflow.com/a/23047888/796152
     */
    [].slice.call(document.querySelectorAll("use[*|href]"))

        /**
         * Filter out all elements whose namespaced `href` attribute doesn't
         * start with `#` (i.e. all non-relative IRI's)
         *
         * Note: we're assuming the `xlink` prefix for the XLink namespace!
         */
        .filter(function(element) {
            return (element.getAttribute("xlink:href").indexOf("#") === 0);
        })

        /**
         * Prepend `window.location` to the namespaced `href` attribute value,
         * in order to make it an absolute IRI
         *
         * Note: we're assuming the `xlink` prefix for the XLink namespace!
         */
        .forEach(function(element) {
            element.setAttribute("xlink:href", baseUrl + element.getAttribute("xlink:href"));
        });

    /** Clip-path attributes */
    [].slice.call(document.querySelectorAll("svg [clip-path]"))
        .filter(function(element) {
            return (element.getAttribute("clip-path").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("clip-path").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("clip-path", newVal);
        });

    /** Mask attributes */
    [].slice.call(document.querySelectorAll("svg [mask]"))
        .filter(function(element) {
            return (element.getAttribute("mask").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("mask").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("mask", newVal);
        });

    /** Pattern attributes */
    [].slice.call(document.querySelectorAll("svg [pattern]"))
        .filter(function(element) {
            return (element.getAttribute("pattern").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("pattern").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("pattern", newVal);
        });

    /** Fill attributes */
    [].slice.call(document.querySelectorAll("svg [fill]"))
        .filter(function(element) {
            return (element.getAttribute("fill").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("fill").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("fill", newVal);
        });
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function initArrayFromPolyfill() {
    if (!Array.from) {
        Array.from = (function () {
            var toStr = Object.prototype.toString;
            var isCallable = function (fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function (value) {
                var number = Number(value);
                if (isNaN(number)) { return 0; }
                if (number === 0 || !isFinite(number)) { return number; }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function (value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
            };

            // The length property of the from method is 1.
            return function from(arrayLike/*, mapFn, thisArg */) {
                // 1. Let C be the this value.
                var C = this;

                // 2. Let items be ToObject(arrayLike).
                var items = Object(arrayLike);

                // 3. ReturnIfAbrupt(items).
                if (arrayLike == null) {
                    throw new TypeError('Array.from requires an array-like object - not null or undefined');
                }

                // 4. If mapfn is undefined, then let mapping be false.
                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                        throw new TypeError('Array.from: when provided, the second argument must be a function');
                    }

                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                        T = arguments[2];
                    }
                }

                // 10. Let lenValue be Get(items, "length").
                // 11. Let len be ToLength(lenValue).
                var len = toLength(items.length);

                // 13. If IsConstructor(C) is true, then
                // 13. a. Let A be the result of calling the [[Construct]] internal method
                // of C with an argument list containing the single item len.
                // 14. a. Else, Let A be ArrayCreate(len).
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                // 16. Let k be 0.
                var k = 0;
                // 17. Repeat, while k < len… (also steps a - h)
                var kValue;
                while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                        A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                        A[k] = kValue;
                    }
                    k += 1;
                }
                // 18. Let putStatus be Put(A, "length", len, true).
                A.length = len;
                // 20. Return A.
                return A;
            };
        }());
    }
}
