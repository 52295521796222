export const vbcnOptions = {
    notice: {
        ignoredPages: [
            '/datenschutzerklaerung',
        ],
        analytics: {
            addInfo: true
        },
        showOverlayOnInit: true,
        backgroundColor: '#493d41',
        textColor: 'white',
        useBackdrop: false,
        backdropColor: '#333',
        backdropOpacity: '0.8',
        centerMode: false,
    },

    texts: {
        "de": {
            initialHeadline: 'Cookies, externe Dienste & Datenschutz',
            customizeHeadline: 'Ihre Datenschutz-Einstellungen',
            explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. \n\n " +
                "Zur Analyse der Zugriffe auf unsere Website verwenden wir Matomo Analytics. \n\n" +
                "Außerdem binden wir Skripte von YouTube ein. \n\n" +
                "Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
            accept_preset_ButtonText: 'Alle akzeptieren',
            declineButtonText: 'Ablehnen ',
            accept_all_ButtonText: 'Alle Cookies akzeptieren',
            decline_all_ButtonText: 'Alle ablehnen',
            customizeButtonText: 'Einstellungen',
            cancel_customizeButtonText: 'Zurück',
            saveButtonText: 'Speichern',
            closeButtonText: '×'
        },
        "en": {
            initialHeadline: 'Accept cookies & Privacy Policy?',
            customizeHeadline: 'Select cookies to accept',
            explanationText: "On your consent cookies are set to optimize your experience on our website. \n\n" +
                "We use [[ Google Analytics / Matomo Analytics ]] to analyze the usage  of our website. \n\n" +
                "We embed scripts from these external service providers: YouTube \n\n" +
                "On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/datenschutz'>here</a> in our privacy statement.",
            accept_preset_ButtonText: 'Accept all',
            declineButtonText: 'Decline',
            accept_all_ButtonText: 'Accept all',
            decline_all_ButtonText: 'Decline all',
            customizeButtonText: 'Customize',
            cancel_customizeButtonText: 'Go back',
            saveButtonText: 'Save',
            closeButtonText: '×'
        }
    },
    sets: {
        "de": [
            {
                id: 'mandatory',
                name: 'Notwendige Cookies',
                description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
                mandatory: true,
                mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
            }, {
                id: 'style',
                name: 'Styling Cookies',
                description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Dabei können personenbezogene Daten übertragen werden.'
            }
        ],
        "en": [
            {
                id: 'mandatory',
                name: 'Essential cookies',
                description: 'Cookies that ensure the functionality and the design of our webpages. No personal data are collected. These cookies are required to save your session settings.',
                mandatory: true,
                mandatory_text: "These cookies are mandatory."
            }, {
                id: 'style',
                name: 'Styling Cookies',
                description: 'Cookies that ensure the design of our webpages. No personal data are collected.'
            }]
    }

};
